/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import $ from 'jquery'
global.$ = $;
global.jQuery = $;

import 'bootstrap'
import '../bootstrap-themes/coreui/_coreui'

import '../../stylesheets/packs/application'
import 'bootstrap-star-rating'
import 'popper.js'

import ChartColor from '../runtime/rr-chart-color'
import DateFormater from '../runtime/rr-date-formater'

global.ChartColor = ChartColor
global.DateFormater = DateFormater

import toastr from 'toastr'
window.toastr = toastr;

import 'select2'

import './toasts_session'

import './custom'
import Rails from 'rails-ujs';
Rails.start();

toastr.options = {
    "closeButton": true
}

// Images

import '../../images/logo.png'
