import $ from 'jquery';

$(document).ready(function() {
    const POLL_INTERVAL = 10000;
    const validStates = ["sdk_sent", "fetching_sales"];

    /**
     * Toggles the enable/disable state of date inputs based on the selected filter value.
     * @param {jQuery} filterSelect - The select element to determine the toggle state.
     * @param {jQuery} startDateInput - The input element for the start date.
     * @param {jQuery} endDateInput - The input element for the end date.
     */
    function handleDateInputs(filterSelect, startDateInput, endDateInput) {
        const toggleDateInputs = () => {
            const isAllSelected = filterSelect.val() === 'all';
            startDateInput.prop('disabled', !isAllSelected);
            endDateInput.prop('disabled', !isAllSelected);

            if (!isAllSelected) {
                startDateInput.val('');
                endDateInput.val('');
            }
        };

        // Initial check on page load
        toggleDateInputs();
        // Add event listener to handle changes
        filterSelect.on('change', toggleDateInputs);
    }

    /**
     * Retrieves appointment IDs from rows with specific transaction states that are not yet fetched.
     * @return {Array} Array of relevant appointment IDs.
     */
    function getRelevantAppointmentIds() {
        return $("tr[data-transaction-state]").map(function() {
            const $row = $(this);

            // Clear cached data to force fresh read from the DOM
            $row.removeData("transaction-state");
            $row.removeData("sale-fetched");

            const state = $row.attr("data-transaction-state"); // Use .attr() for real-time values
            const saleFetched = $row.attr("data-sale-fetched") === "true"; // Cast to boolean

            return validStates.includes(state) && !saleFetched ? $row.data("appointment-id") : null;
        }).get();
    }

    /**
     * Polls the server for transaction state updates for relevant appointments.
     */
    function pollTransactionStates() {
        const appointmentIds = getRelevantAppointmentIds();
        console.log('ids', appointmentIds)
        if (appointmentIds.length > 0) {
            $.ajax({
                url: "/loyalty_admin/appointments/check_transaction_states",
                method: "POST",
                data: { appointment_ids: appointmentIds },
                success(response) {
                    response.data.forEach(appointment => {
                        const targetTd = $(`#transaction-state-${appointment.id}`);
                        targetTd.html(appointment.button_html);
                        $(`#appointment-row-${appointment.id}`).attr("data-transaction-state", appointment.state);
                    });
                },
                error(error) {
                    console.error("Polling error:", error);
                }
            });
        }
    }

    /**
     * Checks and refreshes the transaction state of a single appointment on the appointment detail page.
     */
    function refreshAppointmentStates() {
        const $element = $('#appointment-detail');
        const state = $element.data("transaction-state");
        const appointmentId = $element.data("appointment-id");
        const saleFetched = $element.data("sale-fetched");

        if (validStates.includes(state) && !saleFetched) {
            pollSingleAppointmentState(appointmentId);
        }
    }

    /**
     * Polls the server for the transaction state update of a single appointment.
     * @param {string} appointmentId - The ID of the appointment to poll.
     */
    function pollSingleAppointmentState(appointmentId) {
        $.ajax({
            url: `/loyalty_admin/appointments/${appointmentId}/check_transaction_state`,
            method: "GET",
            success(response) {
                $("#appointment-detail").html(response.transaction_html);
                $(`#transaction-state-${appointmentId}`).html(response.button_html);
            },
            error(error) {
                console.error("Polling error:", error);
            }
        });
    }

    // Initialize date input handling for multiple filters
    handleDateInputs($('#q_start_date_eq'), $('#q_start_date_gteq'), $('#q_start_date_end_of_day_lteq'));
    handleDateInputs($('#q_date_eq'), $('#q_date_gteq'), $('#q_date_end_of_day_lteq'));

    // Start polling if the appointment detail table is present
    if ($('table[data-check-states-with-polling="true"]').length > 0) {
        setInterval(pollTransactionStates, POLL_INTERVAL);
    }

    // Refresh individual appointment states periodically
    setInterval(refreshAppointmentStates, POLL_INTERVAL);
});